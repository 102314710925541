var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "EditLayout",
    {
      attrs: {
        item: _vm.item,
        "submit-button-text": _vm.isNew ? _vm.$t("create") : _vm.$t("save"),
        "supports-photo": "",
        photo: _vm.photo,
      },
      on: {
        submit: function ($event) {
          _vm.isNew ? _vm.create() : _vm.edit()
        },
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "sidebar-middle" }, slot: "sidebar-middle" },
        [
          _c("BaseInput", {
            attrs: {
              label: _vm.$t("country"),
              placeholder: _vm.$t("enterBrandsCountry"),
            },
            model: {
              value: _vm.item.country,
              callback: function ($$v) {
                _vm.$set(_vm.item, "country", $$v)
              },
              expression: "item.country",
            },
          }),
          _c("BaseInput", {
            attrs: {
              label: _vm.$t("founded"),
              type: "number",
              placeholder: _vm.$t("enterFoundedYear"),
            },
            model: {
              value: _vm.item.founding_year,
              callback: function ($$v) {
                _vm.$set(_vm.item, "founding_year", $$v)
              },
              expression: "item.founding_year",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }